import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Card } from 'react-bootstrap';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

const products = [
	{
		id: 1,
		price: 1000,
		status: "Pagada",
		created_at: "25/02/2020",
		paid_at: "26/02/2020",
		due_at: "05/03/2020",
		suspension_at: "05/03/2020",
		file: "Factura 1.pdf",
	}, 
	{
		id: 2,
		price: 1050,
		status: "Pendiente",
		created_at: "25/02/2020",
		paid_at: "",
		due_at: "05/03/2020",
		suspension_at: "05/03/2020",
		file: "Factura 1.pdf",
	},
	{
		id: 1,
		price: 61000,
		status: "Pendiente",
		created_at: "25/02/2020",
		paid_at: "",
		due_at: "05/03/2020",
		suspension_at: "05/03/2020",
		file: "Factura 1.pdf",
	}
];
const columns = [{
  dataField: 'id',
  text: 'Factura Id'
}, {
  dataField: 'price',
  text: 'Precio'
}, {
  dataField: 'status',
  text: 'Estado'
}, {
  dataField: 'created_at',
  text: 'Generada'
}, {
  dataField: 'paid_at',
  text: 'Pagada'
}, {
  dataField: 'due_at',
  text: 'Expiración'
}, {
  dataField: 'suspension_at',
  text: 'Suspensión'
}, {
  dataField: 'file',
  text: 'Archivo'
}];

const InvoiceList = (props) => {
	return (
		<Card>
			<BootstrapTable keyField='id' data={ products } columns={ columns } />
		</Card>
	)
};

export default InvoiceList;