import React, { useState } from "react";
import { SlideDown } from "react-slidedown";
import 'react-slidedown/lib/slidedown.css'
import "./App.css";

const options = [
	{
		type: "header",
		content: "MaiXXXXXXXXXn",
		icon: "Test minimal header",
	},
	{
		type: "link",
		content: "Dashboard",
		icon: "icon-home4",
		url: "/",
	},
	{
		type: "custom",
		content: () => (
			<a href="/" className="nav-link">
				Layout 6{" "}
				<span className="badge bg-transparent align-self-center ml-auto">
					Coming soon
				</span>
			</a>
		),
	},
	{
		type: "divider",
	},
	{
		type: "submenu",
		title: "Título con Subsección",
		icon: "icon-home4",
		options: [
			{
				type: "link",
				content: () => (
					<a href="/" className="nav-link">
						Layout 6{" "}
						<span className="badge bg-transparent align-self-center ml-auto">
							Coming soon
						</span>
					</a>
				),
			},
			{
				type: "divider",
			},
			{
				type: "link",
				url: "/",
				content: "Lol",
			},
			{
				type: "link",
				url: "/",
				content: "Lol",
				icon: "icon-home2",
			},
			{
				type: "link",
				url: "/",
				content: "Lol",
			},
			{
				type: "link",
				url: "/",
				content: "Lol",
				icon: "icon-home2",
			},
		],
	},
	{
		type: "divider",
	},
	{
		type: "link",
		url: "/",
		content: "Lol",
	},
];

const Header = ({content, icon}) => {
	return (
		<li className="nav-item-header">
			<div className="text-uppercase font-size-xs line-height-xs">
				{content}
			</div>
			<i className={icon} title="{content}"></i>
		</li>
	);
}

const Divider = () => {
	return (
		<li className="nav-item-divider"></li>
	);
}

const Link = ({icon, url, content}) => {
	return (
		icon ? (
			<a href={url} className="nav-link">
				<i className={icon}></i> <span>{content}</span>
			</a>
		) : (
			<a href={url} className="nav-link">
				<span>{content}</span>
			</a>
		)
	);
};

const Custom = ({content}) => {
	return (
		<li className="nav-item">{content}</li>
	);
};

const SubMenu = ({showed, title, icon, options, onClickCollapse}) => {
	return (
		<li
			className={
				"nav-item nav-item-submenu" +
				" " +
				(showed == 4 ? "nav-item-open" : "")
			}
			onClick={(e) => onClickCollapse(e, 4)}
		>
			<a href="/#" className="nav-link">
				<i className={icon}></i> <span>{title}</span>
			</a>
			<SlideDown
				as="ul"
				className="nav nav-group-sub"
				style={{ display: "block" }}
				data-submenu-title={title}
				closed={showed != 4}
			>
				{showed == 4 &&
					options.map((v, i) => (
						<Option 
							type={v.type}
							url={v.url}
							content={v.content}
							key={i}
							showed={showed}
							title={v.title}
							icon={v.icon}
							options={v.options}
							onClickCollapse={onClickCollapse}
						/>
					))}
			</SlideDown>
			}
		</li>
	);
};

const Option = ({type, url, content, showed, title, icon, options, onClickCollapse}) => {
	if (type == "header") {
		return <Header content={content, icon}/>;
	}
	else if (type == "divider") {
		return <Divider />;
	}
	else if (type == "link") {
		return <Link icon={icon} url={url} content={content} />;
	}
	else if (type == "custom") {
		return <Custom content={content} />;
	}
	else if (type == "submenu") {
		return <SubMenu 
			showed={showed}
			title={title}
			icon={icon}
			options={options}
			onClickCollapse={onClickCollapse}
		/>
	}
};

function Sidebar(props) {
	const [selected, setSelected] = useState(-1);
	const [showed, setShowed] = useState(-1);

	var _sidebarMainResize = function () {};

	let onClickCollapse = (e, option) => {
		e.preventDefault();
		setShowed(showed == option ? -1 : option);
	};

	return (
		<div className="sidebar sidebar-dark sidebar-main sidebar-expand-md">
			<div className="sidebar-mobile-toggler text-center">
				<a href="/#" className="sidebar-mobile-main-toggle">
					<i className="icon-arrow-left8"></i>
				</a>
				Navigation
				<a href="/#" className="sidebar-mobile-expand">
					<i className="icon-screen-full"></i>
					<i className="icon-screen-normal"></i>
				</a>
			</div>

			<div className="sidebar-content">
				<div className="sidebar-user-material">
					<div className="sidebar-user-material-body">
						<div className="card-body text-center">
							<a href="/#">
								<img
									src="../../../../global_assets/images/placeholders/placeholder.jpg"
									className="img-fluid rounded-circle shadow-1 mb-3"
									width="80"
									height="80"
									alt=""
								/>
							</a>
							<h6 className="mb-0 text-white text-shadow-dark">
								Nombre Usuario
							</h6>
							<span className="font-size-sm text-white text-shadow-dark">
								Nombre Empresa
							</span>
						</div>

						<div className="sidebar-user-material-footer">
							<a
								href="#user-nav"
								className="d-flex justify-content-between align-items-center text-shadow-dark dropdown-toggle"
								data-toggle="collapse"
							>
								<span>Mi Cuenta (no funciona)</span>
							</a>
						</div>
					</div>

					<div className="collapse" id="user-nav">
						<ul className="nav nav-sidebar">
							<li className="nav-item">
								<a href="/#" className="nav-link">
									<i className="icon-user-plus"></i>
									<span>My profile</span>
								</a>
							</li>
							<li className="nav-item">
								<a href="/#" className="nav-link">
									<i className="icon-coins"></i>
									<span>My balance</span>
								</a>
							</li>
							<li className="nav-item">
								<a href="/#" className="nav-link">
									<i className="icon-comment-discussion"></i>
									<span>Messages</span>
									<span className="badge bg-success-400 badge-pill align-self-center ml-auto">
										58
									</span>
								</a>
							</li>
							<li className="nav-item">
								<a href="/#" className="nav-link">
									<i className="icon-cog5"></i>
									<span>Account settings</span>
								</a>
							</li>
							<li className="nav-item">
								<a href="/#" className="nav-link">
									<i className="icon-switch2"></i>
									<span>Logout</span>
								</a>
							</li>
						</ul>
					</div>
				</div>

				<div className="card card-sidebar-mobile">
					<ul className="nav nav-sidebar" data-nav-type="accordion">
						<ul
							className="nav nav-group-sub">
							<li className="nav-item">
								<a
									href="index.html"
									className="nav-link active"
								>
									<i className="icon-home4"></i>
									<span>
										Dashboard
										<span className="d-block font-weight-normal opacity-50">
											No active orders
										</span>
									</span>
								</a>
							</li>
						</ul>
						{options.map((v, i) => (
							<Option 
								type={v.type}
								url={v.url}
								content={v.content}
								key={i}
								showed={showed}
								title={v.title}
								icon={v.icon}
								options={v.options}
								onClickCollapse={onClickCollapse}
							/>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
}

export default Sidebar;
