import React from 'react';
import Sidebar from './Sidebar.js';
import InvoiceList from './InvoiceList.js';
import InvoiceForm from './components/InvoiceForm.js';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

function App() {
  return (
  <React.Fragment>
  <div className="navbar navbar-expand-md navbar-light navbar-static">

    <div className="navbar-header navbar-dark d-none d-md-flex align-items-md-center">
      <div className="navbar-brand navbar-brand-md">
        <a href="index.html" className="d-inline-block">
          <img src="../../../../global_assets/images/logo_light.png" alt=""/>
        </a>
      </div>
      
      <div className="navbar-brand navbar-brand-xs">
        <a href="index.html" className="d-inline-block">
          <img src="../../../../global_assets/images/logo_icon_light.png" alt=""/>
        </a>
      </div>
    </div>
  

    <div className="d-flex flex-1 d-md-none">
      <div className="navbar-brand mr-auto">
        <a href="index.html" className="d-inline-block">
          <img src="../../../../global_assets/images/logo_dark.png" alt=""/>
        </a>
      </div>  

      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile">
        <i className="icon-tree5"></i>
      </button>

      <button className="navbar-toggler sidebar-mobile-main-toggle" type="button">
        <i className="icon-paragraph-justify3"></i>
      </button>
    </div>


    <div className="collapse navbar-collapse" id="navbar-mobile">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a href="/#" className="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block">
            <i className="icon-paragraph-justify3"></i>
          </a>
        </li>

        <li className="nav-item dropdown">
          <a href="/#" className="navbar-nav-link dropdown-toggle caret-0" data-toggle="dropdown">
            <i className="icon-people"></i>
            <span className="d-md-none ml-2">Users</span>
            <span className="badge badge-mark border-pink-400 ml-auto ml-md-0"></span>
          </a>
          
          <div className="dropdown-menu dropdown-content wmin-md-300">
            <div className="dropdown-content-header">
              <span className="font-weight-semibold">Users online</span>
              <a href="/#" className="text-default"><i className="icon-search4 font-size-base"></i></a>
            </div>

            <div className="dropdown-content-body dropdown-scrollable">
              <ul className="media-list">
                <li className="media">
                  <div className="mr-3">
                    <img src="../../../../global_assets/images/placeholders/placeholder.jpg" width="36" height="36" className="rounded-circle" alt=""/>
                  </div>
                  <div className="media-body">
                    <a href="/#" className="media-title font-weight-semibold">Jordana Ansley</a>
                    <span className="d-block text-muted font-size-sm">Lead web developer</span>
                  </div>
                  <div className="ml-3 align-self-center"><span className="badge badge-mark border-success"></span></div>
                </li>

                <li className="media">
                  <div className="mr-3">
                    <img src="../../../../global_assets/images/placeholders/placeholder.jpg" width="36" height="36" className="rounded-circle" alt=""/>
                  </div>
                  <div className="media-body">
                    <a href="/#" className="media-title font-weight-semibold">Will Brason</a>
                    <span className="d-block text-muted font-size-sm">Marketing manager</span>
                  </div>
                  <div className="ml-3 align-self-center"><span className="badge badge-mark border-danger"></span></div>
                </li>

                <li className="media">
                  <div className="mr-3">
                    <img src="../../../../global_assets/images/placeholders/placeholder.jpg" width="36" height="36" className="rounded-circle" alt=""/>
                  </div>
                  <div className="media-body">
                    <a href="/#" className="media-title font-weight-semibold">Hanna Walden</a>
                    <span className="d-block text-muted font-size-sm">Project manager</span>
                  </div>
                  <div className="ml-3 align-self-center"><span className="badge badge-mark border-success"></span></div>
                </li>

                <li className="media">
                  <div className="mr-3">
                    <img src="../../../../global_assets/images/placeholders/placeholder.jpg" width="36" height="36" className="rounded-circle" alt=""/>
                  </div>
                  <div className="media-body">
                    <a href="/#" className="media-title font-weight-semibold">Dori Laperriere</a>
                    <span className="d-block text-muted font-size-sm">Business developer</span>
                  </div>
                  <div className="ml-3 align-self-center"><span className="badge badge-mark border-warning-300"></span></div>
                </li>

                <li className="media">
                  <div className="mr-3">
                    <img src="../../../../global_assets/images/placeholders/placeholder.jpg" width="36" height="36" className="rounded-circle" alt=""/>
                  </div>
                  <div className="media-body">
                    <a href="/#" className="media-title font-weight-semibold">Vanessa Aurelius</a>
                    <span className="d-block text-muted font-size-sm">UX expert</span>
                  </div>
                  <div className="ml-3 align-self-center"><span className="badge badge-mark border-grey-400"></span></div>
                </li>
              </ul>
            </div>

            <div className="dropdown-content-footer bg-light">
              <a href="/#" className="text-grey mr-auto">All users</a>
              <a href="/#" className="text-grey"><i className="icon-gear"></i></a>
            </div>
          </div>
        </li>
      </ul>

      <span className="badge bg-pink-400 badge-pill ml-md-3 mr-md-auto">16 orders</span>

      <ul className="navbar-nav">
        <li className="nav-item dropdown">
          <a href="/#" className="navbar-nav-link dropdown-toggle" data-toggle="dropdown">
            <img src="../../../../global_assets/images/lang/gb.png" className="img-flag mr-2" alt=""/>
            Nada funciona
          </a>

          <div className="dropdown-menu dropdown-menu-right">
            <a href="/#" className="dropdown-item english active"><img src="../../../../global_assets/images/lang/gb.png" className="img-flag" alt=""/> English</a>
            <a href="/#" className="dropdown-item ukrainian"><img src="../../../../global_assets/images/lang/ua.png" className="img-flag" alt=""/> Українська</a>
            <a href="/#" className="dropdown-item deutsch"><img src="../../../../global_assets/images/lang/de.png" className="img-flag" alt=""/> Deutsch</a>
            <a href="/#" className="dropdown-item espana"><img src="../../../../global_assets/images/lang/es.png" className="img-flag" alt=""/> España</a>
            <a href="/#" className="dropdown-item russian"><img src="../../../../global_assets/images/lang/ru.png" className="img-flag" alt=""/> Русский</a>
          </div>
        </li>

        <li className="nav-item dropdown">
          <a href="/#" className="navbar-nav-link dropdown-toggle caret-0" data-toggle="dropdown">
            <i className="icon-bubbles4"></i>
            <span className="d-md-none ml-2">Messages</span>
            <span className="badge badge-mark border-pink-400 ml-auto ml-md-0"></span>
          </a>
          
          <div className="dropdown-menu dropdown-menu-right dropdown-content wmin-md-350">
            <div className="dropdown-content-header">
              <span className="font-weight-semibold">Messages</span>
              <a href="/#" className="text-default"><i className="icon-compose"></i></a>
            </div>

            <div className="dropdown-content-body dropdown-scrollable">
              <ul className="media-list">
                <li className="media">
                  <div className="mr-3 position-relative">
                    <img src="../../../../global_assets/images/placeholders/placeholder.jpg" width="36" height="36" className="rounded-circle" alt=""/>
                  </div>

                  <div className="media-body">
                    <div className="media-title">
                      <a href="/#">
                        <span className="font-weight-semibold">James Alexander</span>
                        <span className="text-muted float-right font-size-sm">04:58</span>
                      </a>
                    </div>

                    <span className="text-muted">who knows, maybe that would be the best thing for me...</span>
                  </div>
                </li>

                <li className="media">
                  <div className="mr-3 position-relative">
                    <img src="../../../../global_assets/images/placeholders/placeholder.jpg" width="36" height="36" className="rounded-circle" alt=""/>
                  </div>

                  <div className="media-body">
                    <div className="media-title">
                      <a href="/#">
                        <span className="font-weight-semibold">Margo Baker</span>
                        <span className="text-muted float-right font-size-sm">12:16</span>
                      </a>
                    </div>

                    <span className="text-muted">That was something he was unable to do because...</span>
                  </div>
                </li>

                <li className="media">
                  <div className="mr-3">
                    <img src="../../../../global_assets/images/placeholders/placeholder.jpg" width="36" height="36" className="rounded-circle" alt=""/>
                  </div>
                  <div className="media-body">
                    <div className="media-title">
                      <a href="/#">
                        <span className="font-weight-semibold">Jeremy Victorino</span>
                        <span className="text-muted float-right font-size-sm">22:48</span>
                      </a>
                    </div>

                    <span className="text-muted">But that would be extremely strained and suspicious...</span>
                  </div>
                </li>

                <li className="media">
                  <div className="mr-3">
                    <img src="../../../../global_assets/images/placeholders/placeholder.jpg" width="36" height="36" className="rounded-circle" alt=""/>
                  </div>
                  <div className="media-body">
                    <div className="media-title">
                      <a href="/#">
                        <span className="font-weight-semibold">Beatrix Diaz</span>
                        <span className="text-muted float-right font-size-sm">Tue</span>
                      </a>
                    </div>

                    <span className="text-muted">What a strenuous career it is that I've chosen...</span>
                  </div>
                </li>

                <li className="media">
                  <div className="mr-3">
                    <img src="../../../../global_assets/images/placeholders/placeholder.jpg" width="36" height="36" className="rounded-circle" alt=""/>
                  </div>
                  <div className="media-body">
                    <div className="media-title">
                      <a href="/#">
                        <span className="font-weight-semibold">Richard Vango</span>
                        <span className="text-muted float-right font-size-sm">Mon</span>
                      </a>
                    </div>
                    
                    <span className="text-muted">Other travelling salesmen live a life of luxury...</span>
                  </div>
                </li>
              </ul>
            </div>

            <div className="dropdown-content-footer bg-light">
              <a href="/#" className="text-grey mr-auto">All messages</a>
              <div>
                <a href="/#" className="text-grey" data-popup="tooltip" title="Mark all as read"><i className="icon-radio-unchecked"></i></a>
                <a href="/#" className="text-grey ml-2" data-popup="tooltip" title="Settings"><i className="icon-cog3"></i></a>
              </div>
            </div>
          </div>
        </li>

        <li className="nav-item dropdown dropdown-user">
          <a href="/#" className="navbar-nav-link d-flex align-items-center dropdown-toggle" data-toggle="dropdown">
            <img src="../../../../global_assets/images/placeholders/placeholder.jpg" className="rounded-circle mr-2" height="34" alt=""/>
            <span>Nombre Usuario</span>
          </a>

          <div className="dropdown-menu dropdown-menu-right">
            <a href="/#" className="dropdown-item"><i className="icon-user-plus"></i> My profile</a>
            <a href="/#" className="dropdown-item"><i className="icon-coins"></i> My balance</a>
            <a href="/#" className="dropdown-item"><i className="icon-comment-discussion"></i> Messages <span className="badge badge-pill bg-indigo-400 ml-auto">58</span></a>
            <div className="dropdown-divider"></div>
            <a href="/#" className="dropdown-item"><i className="icon-cog5"></i> Account settings</a>
            <a href="/#" className="dropdown-item"><i className="icon-switch2"></i> Logout</a>
          </div>
        </li>
      </ul>
    </div>
    
  </div>

          
  <div className="page-content">

    <Sidebar></Sidebar>

    <div className="content-wrapper">

      <div className="page-header">
        <div className="page-header-content header-elements-md-inline">
          <div className="page-title d-flex">
            <h4><i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">Home</span> - Dashboard</h4>
            <a href="/#" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
          </div>

          <div className="header-elements d-none text-center text-md-left mb-3 mb-md-0">
            <div className="btn-group">
              <button type="button" className="btn bg-indigo-400"><i className="icon-stack2 mr-2"></i> Nueva Factura</button>
              <button type="button" className="btn bg-indigo-400 dropdown-toggle" data-toggle="dropdown"></button>
              <div className="dropdown-menu dropdown-menu-right">
                <div className="dropdown-header">Actions</div>
                <a href="/#" className="dropdown-item"><i className="icon-file-eye"></i> View reports</a>
                <a href="/#" className="dropdown-item"><i className="icon-file-plus"></i> Edit reports</a>
                <a href="/#" className="dropdown-item"><i className="icon-file-stats"></i> Statistics</a>
                <div className="dropdown-header">Export</div>
                <a href="/#" className="dropdown-item"><i className="icon-file-pdf"></i> Export to PDF</a>
                <a href="/#" className="dropdown-item"><i className="icon-file-excel"></i> Export to CSV</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content pt-0">
        <InvoiceList />
      </div>

    </div>

  </div>
  </React.Fragment>
  );
}

export default App;
